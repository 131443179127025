<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{this.singular}}: {{this.data.name}}</h1>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="6" sm="12">
          <v-btn v-if="isAllowed('supplier','u')" @click="isEditable = !isEditable" class="mr-2">
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit</span>
          </v-btn>
          <span v-if="isEditable">
            <v-btn @click="update" color="success">Save</v-btn>
            <v-btn v-if="isAllowed('supplier','d')" class="ml-2" color="error" @click="deleteData">Delete</v-btn>
          </span>
          <v-form :disabled="!isEditable" class="mt-2">
            <v-text-field
                label="Company Name"
                outlined
                v-model="data.name"
            />
            <v-text-field
                label="Contact Name"
                outlined
                v-model="data.contactName"
            />
            <v-text-field
                label="Phone"
                outlined
                v-model="data.phone"
            />
            <v-text-field
                label="Address"
                outlined
                v-model="data.address"
            />
            <v-text-field
                label="Email"
                outlined
                v-model="data.email"
            />
          </v-form>
        </v-col>
        <v-col lg="6" sm="12">
          <v-card outlined>
            <v-card-text>
              <h3>Products</h3>
              <v-data-table
                  :headers="productTable.headers"
                  :items="productTable.items"
                  :options.sync="productOptions"
                  :server-items-length="productTable.pagination.dataCount"
                  :loading="productTable.pagination.loading"
                  class="elevation-0"
                  :footer-props="{
                    itemsPerPageOptions: productTable.pagination.pageSizeOptions
                  }"
                  @click:row="productTableRowClick"
              >
                <template v-slot:footer class="d-flex flex-row">
                  <v-text-field label="Go To Page" :min="1" :max="parseInt(productTable.pagination.dataCount/productOptions.itemsPerPage)+(productTable.pagination.dataCount%productOptions.itemsPerPage!==0?1:0)" type="number" dense outlined v-model="productOptions.page"/>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="6" sm="12">
          <v-card outlined>
            <v-card-text>
              <h3>Purchase Orders</h3>
              <v-data-table
                  :headers="purchaseOrderTable.headers"
                  :items="purchaseOrderTable.items"
                  :options.sync="purchaseOrderOptions"
                  :server-items-length="purchaseOrderTable.pagination.dataCount"
                  :loading="purchaseOrderTable.pagination.loading"
                  class="elevation-0"
                  :footer-props="{
                    itemsPerPageOptions: purchaseOrderTable.pagination.pageSizeOptions
                  }"
                  @click:row="purchaseOrderTableRowClick"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span v-if="item.createdAt">{{utils.formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.sealedAt="{ item }">
                  <span v-if="item.sealedAt">{{utils.formatDate(item.sealedAt) }}</span>
                </template>
                <template v-slot:item.numItems="{ item }">
                  <span>{{item.PurchaseOrderLineItems?.length||0}}</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <span :class="`v-btn pa-1 ${utils.getPOStatusColor(item.status)}`" style="font-size: 10px">{{utils.parsePOStatus(item.status)}}</span>
                </template>
                <template v-slot:item.createdBy="{ item }">
                  <span>{{lookupUsername(item.createdBy) }}</span>
                </template>
                <template v-slot:item.sealedBy="{ item }">
                  <span>{{lookupUsername(item.sealedBy) }}</span>
                </template>
                <template v-slot:footer class="d-flex flex-row">
                  <v-text-field label="Go To Page" :min="1" :max="parseInt(purchaseOrderTable.pagination.dataCount/purchaseOrderOptions.itemsPerPage)+(purchaseOrderTable.pagination.dataCount%purchaseOrderOptions.itemsPerPage!==0?1:0)" type="number" dense outlined v-model="purchaseOrderOptions.page"/>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="6" sm="12">
          <v-card outlined>
            <v-card-text>
              <h3>Receiving Reports</h3>
              <v-data-table
                  :headers="receivingReportTable.headers"
                  :items="receivingReportTable.items"
                  :options.sync="receivingReportOptions"
                  :server-items-length="receivingReportTable.pagination.dataCount"
                  :loading="receivingReportTable.pagination.loading"
                  class="elevation-0"
                  :footer-props="{
                    itemsPerPageOptions: receivingReportTable.pagination.pageSizeOptions
                  }"
                  @click:row="receivingReportTableRowClick"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span v-if="item.createdAt">{{utils.formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.sealedAt="{ item }">
                  <span v-if="item.sealedAt">{{utils.formatDate(item.sealedAt) }}</span>
                </template>
                <template v-slot:item.numItems="{ item }">
                  <span>{{item.ReceivingReportLineItems?.length||0}}</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <span :class="`v-btn pa-1 ${utils.getRRStatusColor(item.status)}`" style="font-size: 10px">{{utils.parseRRStatus(item.status)}}</span>
                </template>
                <template v-slot:item.createdBy="{ item }">
                  <span>{{lookupUsername(item.createdBy) }}</span>
                </template>
                <template v-slot:item.sealedBy="{ item }">
                  <span>{{lookupUsername(item.sealedBy) }}</span>
                </template>
                <template v-slot:footer class="d-flex flex-row">
                  <v-text-field label="Go To Page" :min="1" :max="parseInt(receivingReportTable.pagination.dataCount/receivingReportOptions.itemsPerPage)+(receivingReportTable.pagination.dataCount%receivingReportOptions.itemsPerPage!==0?1:0)" type="number" dense outlined v-model="receivingReportOptions.page"/>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import utils from '../../plugins/helpers'
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        utils: utils,

        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        allSchemas: {
          product: {
            table: 'productTable',
            options: 'productOptions',
            api: 'productData'
          },
          purchaseOrder: {
            table: 'purchaseOrderTable',
            options: 'purchaseOrderOptions',
            api: 'purchaseOrderData'
          },
          receivingReport: {
            table: 'receivingReportTable',
            options: 'receivingReportOptions',
            api: 'receivingReportData'
          }
        },

        productTable: {
          headers: [
            {text: 'Product ID', align: 'start', value: 'id'},
            { text: 'Name', value: 'name' },
            { text: 'SKU', value: 'sku' }
          ],
          items: [],
          pagination: {
            loading: false,
            dataCount: 0,
            pageSizeOptions: [5, 10, 15, 30, 50, -1]
          }
        },
        productOptions: {
          search: "",
          sortBy: ['id'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 10
        },

        purchaseOrderTable: {
          headers: [
            {text: 'ID', align: 'start', value: 'id'},
            { text: 'Status', value: 'status' },
            { text: '# of Items', value: 'numItems' },
            { text: 'Date Created', value: 'createdAt' },
            { text: 'Created By', value: 'createdBy' },
            { text: 'Date Sealed', value: 'sealedAt' },
            { text: 'Sealed By', value: 'sealedBy' },
          ],
          items: [],
          pagination: {
            loading: false,
            dataCount: 0,
            pageSizeOptions: [5, 10, 15, 30, 50, -1]
          }
        },
        purchaseOrderOptions: {
          search: "",
          sortBy: ['id'],
          sortDesc: [false],
          page: 1,
          itemsPerPage: 10
        },

        receivingReportTable: {
          headers: [
            {text: 'ID', align: 'start', value: 'id'},
            { text: 'Status', value: 'status' },
            { text: '# of Items', value: 'numItems' },
            { text: 'Date Created', value: 'createdAt' },
            { text: 'Created By', value: 'createdBy' },
            { text: 'Date Sealed', value: 'sealedAt' },
            { text: 'Sealed By', value: 'sealedBy' },
          ],
          items: [],
          pagination: {
            loading: false,
            dataCount: 0,
            pageSizeOptions: [5, 10, 15, 30, 50, -1]
          }
        },
        receivingReportOptions: {
          search: "",
          sortBy: ['id'],
          sortDesc: [false],
          page: 1,
          itemsPerPage: 10
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        data: [],
        singular: "Supplier",
        singularLower: "supplier",
        plural: "Suppliers",
        pluralLower: "suppliers",
        isEditable: false,
      }
    },
    async mounted(){
      try {
        let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
        if(res.data.error) throw res.data.error

        this.productTable.items = [];
        this.purchaseOrderTable.items = [];
        this.receivingReportTable.items = [];

        await this.getTableData('product');
        await this.getTableData('purchaseOrder');
        await this.getTableData('receivingReport');

        console.log(this.purchaseOrderTable.items)

        this.data = res.data.data
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loader = false;
      }
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", "lookupUsername"])
    },
    watch: {
      productOptions: {
        async handler () {
          await this.getTableData('product');
        },
        deep: true,
      },
      purchaseOrderOptions: {
        async handler () {
          await this.getTableData('purchaseOrder');
        },
        deep: true,
      },
      receivingReportOptions: {
        async handler () {
          await this.getTableData('receivingReport');
        },
        deep: true,
      }
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async update(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async deleteData(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack(this.singular+" Deleted 🎉", "success");
          await this.$router.push({ path: `/${this.pluralLower}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      productTableRowClick(e){
        this.$router.push({path: `/product/view/${e.parent?e.parent:e.id}`})
      },
      purchaseOrderTableRowClick(e){
        this.$router.push({path: `/purchaseorders/view/${e.id}`})
      },
      receivingReportTableRowClick(e){
        this.$router.push({path: `/receiving/view/${e.id}`})
      },
      async getTableData(table){
        let schema = this.allSchemas[table];
        try{
          this[schema.table].pagination.loading = true;

          if(!this[schema.options].page || this[schema.options].page<=0) return;

          let paginationData = {
            page: this[schema.options].page,
            limit: this[schema.options].itemsPerPage,
            sort: (this[schema.options].sortBy.length>0)?this[schema.options].sortBy[0]:"id",
            order: this[schema.options].sortDesc[0],
          }

          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/suppliers/${schema.api}/${this.$route.params.id}?${uriFields}`)
          if(res.data.error) throw res.data.error
          this[schema.table].items = res.data.data

          //used for pagination
          this[schema.table].pagination.dataCount = res.data.total
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this[schema.table].pagination.loading = false;
        }
      },
    }
  }
</script>
